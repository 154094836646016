export default [
  {
    path: '/statisticalManagement',
    name: 'statisticalManagement',
    component: () => import(/* webpackChunkName: "statisticalManagement" */ './index'),
    meta: {
      type: 'statisticalManagement'
    },
    redirect: '/statisticalManagement/deviceCount',
    children: [
      {
        path: 'deviceCount',
        name: 'deviceCount',
        component: () => import(/* webpackChunkName: "statisticalManagement" */ './deviceCount')
      },
      {
        path: 'appCount',
        name: 'appCount',
        component: () => import(/* webpackChunkName: "statisticalManagement" */ './appCount')
      },
      {
        path: 'systemOsCount',
        name: 'systemOsCount',
        component: () => import(/* webpackChunkName: "statisticalManagement" */ './systemOsCount')
      },
      {
        path: 'versionCount',
        name: 'versionCount',
        component: () => import(/* webpackChunkName: "statisticalManagement" */ './versionCount')
      },
      {
        path: 'baseStatusCount',
        name: 'baseStatusCount',
        component: () => import(/* webpackChunkName: "statisticalManagement" */ './baseStatusCount')
      },
      {
        path: 'electronicScalesReminder',
        name: 'electronicScalesReminder',
        component: () => import(/* webpackChunkName: "statisticalManagement" */ './electronicScalesReminder')
      },
      {
        path: 'deviceSelfTest',
        name: 'deviceSelfTest',
        component: () => import(/* webpackChunkName: "statisticalManagement" */ './deviceSelfTest')
      }
    ]
  }
]
